import React, { FC, Suspense } from "react";
import { ICreatePaymentLinkModal } from "./interfaces/ICreatePaymentLinkModal";
import ExlyLoader from "ui/modules/ExlyLoader";

const CreatePaymentLinkModalBase = React.lazy(
  () => import("./CreatePaymentLinkModalBase")
);

const CreatePaymentLinkModal: FC<ICreatePaymentLinkModal> = (props) => {
  const { open } = props;

  if (!open) return null;

  return (
    <Suspense fallback={<ExlyLoader />}>
      <CreatePaymentLinkModalBase {...props} />
    </Suspense>
  );
};

export default CreatePaymentLinkModal;
