import React from "react";
import styles from "../SalesPagesListItemCard.module.css";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { Shimmer } from "@my-scoot/component-library-legacy";

export const SalesPagesListItemCardShimmer = () => {
  const isDesktop = useDesktopMediaQuery();
  return isDesktop ? (
    <div className={styles.container}>
      <div className="d-flex align-items-center">
        <Shimmer height="66px" width="132px" borderRadius="5px" />
        <div className="ml-2">
          <Shimmer
            height="20px"
            width="120px"
            borderRadius="5px"
            wrapper_classname="mb-1"
          />
          <Shimmer height="16px" width="100px" borderRadius="5px" />
        </div>
      </div>
      <Shimmer
        height="66px"
        width="80%"
        borderRadius="5px"
        wrapper_classname="m-auto"
      />
      <Shimmer
        height="26px"
        width="80%"
        borderRadius="5px"
        wrapper_classname="my-auto ml-auto"
      />
    </div>
  ) : (
    <div className={styles.container}>
      <div className="d-flex align-items-center justify-content-between px-2 py-3 border-bottom">
        <Shimmer height="14px" width="50%" borderRadius="8px" />
        <Shimmer height="14px" width="26px" borderRadius="8px" />
      </div>
      <div className="p-2 d-flex">
        <div className="mr-2">
          <Shimmer width="90px" height="90px" borderRadius="8px" />
        </div>
        <div className="d-flex flex-column w-100">
          <div className="flex-grow-1">
            <Shimmer
              width="50px"
              height="7px"
              borderRadius="8px"
              wrapper_classname="mb-2"
            />
            <Shimmer height="9px" borderRadius="8px" wrapper_classname="mb-1" />
            <Shimmer width="50%" height="9px" borderRadius="8px" />
          </div>
          <Shimmer height="32px" borderRadius="4px" />
        </div>
      </div>
    </div>
  );
};
