import { feature_activation_keys } from "features/FeatureActivationStatus/FeatureActivationStatus.constants";
import useFeaturesActivationStatus from "features/FeatureActivationStatus/utils/useFeaturesActivationStatus";

const useIsVdoCipherVideoHostingEnabled = () => {
  const { loading, featuresActivationStatus } = useFeaturesActivationStatus();
  const isVdoCipherEnabled =
    !featuresActivationStatus[
      feature_activation_keys.is_vdeocipher_disabled.key
    ];

  return { loading, isVdoCipherEnabled };
};

export default useIsVdoCipherVideoHostingEnabled;
