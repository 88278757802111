import React from "react";
import {
  ThemeProvider,
  creatorToolTheme,
} from "@my-scoot/component-library-legacy";

const withComponentLibraryTheme = (Component) => {
  const WrappedComponent = (props) => (
    <ThemeProvider theme={creatorToolTheme}>
      <Component {...props} />
    </ThemeProvider>
  );
  return WrappedComponent;
};

export default withComponentLibraryTheme;
