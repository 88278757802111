import React from "react";
import styles from "./DesktopOnlyFeatureModal.module.css";
import ExlyImage from "common/Components/ExlyImage";
import ExlyModal from "common/Components/ExlyModal";

/**
 * Modal to be shown when a user tries to access a feature that is not supported on mobiles. e.g, GJS builder
 */
// NOTE: lazy loading this modal was disabling scroll in select template page and sales page list pop over component
const DesktopOnlyFeatureModal = ({ isOpen, onClose, description }) => {
  return (
    <ExlyModal
      open={isOpen}
      onClose={onClose}
      mobile_modal_props={{ classes: { paper: styles.paperRoot } }}
      showSecondaryBtn={false}
      primaryBtnProps={{ fullWidth: true }}
      customHeader={<></>}
      onPrimaryBtnClick={onClose}
      primaryBtnText="Okay, got it!"
    >
      <div className={styles.content}>
        <ExlyImage
          src="assets/vectors/common/desktop_only_feature.svg"
          className={styles.image}
        />
        <div className={styles.title}>Desktop-Only Feature!</div>
        {description ? (
          <div className={styles.description}>{description}</div>
        ) : null}
      </div>
    </ExlyModal>
  );
};

export default DesktopOnlyFeatureModal;
