import React from "react";
import { field_data } from "ui/pages/transactions/AllTransaction/AllTransactionTableConfig";
import { is_empty } from "utils/validations";

const GSTDetailsField = ({ record }) => {
  const { [field_data.customer_gst_info]: customGSTInfo } = record;

  if (is_empty(customGSTInfo)) return "N/A";

  const {
    [field_data.gst_number]: gstin = "N/A",
    [field_data.gst_name]: gstName = "N/A",
  } = customGSTInfo;

  return (
    <div>
      <div>
        <strong>Company Name</strong>: {gstName}
      </div>
      <div>
        <strong>GSTIN</strong>: {gstin}
      </div>
    </div>
  );
};

export default GSTDetailsField;
