export const REQUEST_ABORT_SIGNALS = {
  validate_refund: "validate_refund",
};

export const TRANSACTIONS_COLUMN_KEYS = {
  customer_phone: "customer_phone",
  customer_country_code: "customer_country_code",
  payout_status: "payout_status",
};

export const TRANSACTIONS_LEARN_MORE_HREFS = {
  MANAGE_TRANSACTIONS:
    "https://support.exlyapp.com/support/solutions/84000148047",
  ALL_TRANSACTIONS_VIDEO: "https://www.youtube-nocookie.com/embed/annmmX--wsA",
  MANAGE_SUBSCRIPTION_VIDEO:
    "https://www.youtube-nocookie.com/embed/mBJJokx2b10",
};
