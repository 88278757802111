export const PAYMENT_LINKS_ACTIONS = {
  CREATE_PAYMENT_LINKS: 1,
  MANAGE_PAYMENT_LINKS: 2,
};

export const PAYMENT_LINKS_URL_PATHS = {
  EXISTING_PAYMENT_LINKS: "/payments/exly/dynamiclinks/all",
  CREATE_LINK: "/CreateLink",
};

export const PAYMENT_LINKS_LEARN_MORE_HREFS = {
  PAYMENT_LINKS:
    "https://support.exlyapp.com/support/solutions/articles/84000347732-payment-links-on-exly",
};
