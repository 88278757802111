import React from "react";
import { LazyLoadRoutes } from "routes/lazyLoadRoutes";
import { Route } from "react-router-dom";
import {
  GJS_PAGE_BUILDER_ROUTE_IDS,
  GJS_PAGE_BUILDER_ROUTE_KEYS,
} from "./GJSSalePageBuilder.constants";

const GJSPageBuilder = React.lazy(() => import("../GJSPageBuilder"));

export const GJS_PAGE_BUILDER_CUSTOM_ROUTES = [
  <Route
    exact
    key={GJS_PAGE_BUILDER_ROUTE_IDS[GJS_PAGE_BUILDER_ROUTE_KEYS.update]}
    path={GJS_PAGE_BUILDER_ROUTE_IDS[GJS_PAGE_BUILDER_ROUTE_KEYS.update]}
    render={(props) => (
      <LazyLoadRoutes props={props} LazyElement={GJSPageBuilder} />
    )}
    noLayout
  />,
];
