import React, { Suspense } from "react";
const ChangeListingModal = React.lazy(() => import("./ChangeListingModal"));

const LazyLoadedChangeListingModal = (props) => {
  if (!props.open) return null;

  return (
    <Suspense fallback={null}>
      <ChangeListingModal {...props} />
    </Suspense>
  );
};

export default LazyLoadedChangeListingModal;
