import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { logError } from "utils/error";

export const duplicateForm = async (questionairreUuid) => {
  try {
    const {
      data: { questionairre_uuid },
    } = await dataProvider.custom_request(
      FORMSV2_API.POST_DUPLICATE_FORM,
      apiMethods.POST,
      {
        questionairre_uuid: questionairreUuid,
      }
    );
    return questionairre_uuid;
  } catch (error) {
    logError({
      error,
      when: "handleDuplicateForm",
      occuredAt:
        "src/features/RecordedContent/modules/FormsV2/utils/FormsV2.utils.jsx",
    });
    return {};
  }
};

export const createTinyFormLink = async (contentUuid) => {
  try {
    const {
      data: { content_url },
    } = await dataProvider.custom_request(
      FORMSV2_API.POST_CREATE_COPY_FORM_LINK,
      apiMethods.POST,
      {
        content_uuid: contentUuid,
      }
    );
    return content_url;
  } catch (error) {
    logError({
      error,
      when: "createCopyFormLink",
      occuredAt:
        "src/features/RecordedContent/modules/FormsV2/utils/FormsV2.utils.jsx",
    });
    return {};
  }
};

export const FORMSV2_API = {
  GET_PAGINATED_FORMS_LIST: "host/content/forms/paginated/list",
  GET_PAGINATED_FORM_QUESTIONS_LIST:
    "host/recorded/form/questions/paginated/list",
  POST_DUPLICATE_FORM: "host/content/questionairre/duplicate",
  POST_CREATE_COPY_FORM_LINK: "host/recorded/lesson/customer/url/get",
  GET_PAGINATED_FORM_RESPONSES: "host/recorded/responses/paginated/list",
  GET_PAGINATED_PROGRESS_TRACKING_FORM_RESPONSES_MOBILE:
    "host/recorded/responses/paginated/list/mobile",
  GET_PAGINATED_PROGRESS_TRACKING_FORM_RESPONSES_DESKTOP:
    "host/recorded/responses/paginated/list/desktop",
};
