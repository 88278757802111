import constants from "constants/constants";
import { INR } from "features/Listings/Listings.constants";
import React, { FC } from "react";
import { getUserCurrencySymbol } from "utils/AuthUtil";
import { IMoneyField } from "./interfaces/IMoneyField";
import { EXISTING_PAYMENT_LINKS_COLUMN_KEYS } from "../../constants/ExistingPaymentLinks.constants";

const MoneyField: FC<IMoneyField> = ({ record, source }) => {
  return (
    <span>
      {record.currency !== INR &&
      source === EXISTING_PAYMENT_LINKS_COLUMN_KEYS.link_price
        ? constants.CURRENCY_SYMBOL_MAP[
            record.currency as keyof typeof constants.CURRENCY_SYMBOL_MAP
          ]
        : getUserCurrencySymbol()}
      &nbsp;{record[source]}
    </span>
  );
};

export default MoneyField;
