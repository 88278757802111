import React from "react";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import { WhatsAppButton } from "webpage-leads/components/CustomColumns";
import { IPaymentLinksTransactions } from "../../interfaces/IPaymentLinksTransactions";
import { TRANSACTIONS_COLUMN_KEYS } from "features/Transactions/modules/GSTDetailsField/constants/Transactions.constants";

const WhatsappNode = (record: IPaymentLinksTransactions.ITransaction) => {
  const isCustomerDetailsHidden = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SECTIONS.ALL_CUSTOMERS
  );

  return (
    <>
      {!record.customer_phone || isCustomerDetailsHidden ? null : (
        <WhatsAppButton
          record={record}
          source={TRANSACTIONS_COLUMN_KEYS.customer_phone}
          countryCodeKey={TRANSACTIONS_COLUMN_KEYS.customer_country_code}
        />
      )}
    </>
  );
};

export default WhatsappNode;
