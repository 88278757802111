import { appendUrlParams } from "utils/urlUtils";
import { sales_page_detail_keys } from "../constants/SalesPages.constants";
import { checkPgBuilderSourceIsGJS } from "./checkPgBuilderSourceIsGJS";
import { getCreatorHostsiteURL } from "features/Account/utils/getCreatorPageDetails";

export const getSalePagePathPrefix = ({ salePageDetail = {} }) =>
  checkPgBuilderSourceIsGJS(
    salePageDetail[sales_page_detail_keys.page_builder_source]
  )
    ? "new-offer"
    : "offer";

export const getSalePagePathname = ({ salePageDetail = {} }) => {
  const path = getSalePagePathPrefix({ salePageDetail });
  return appendUrlParams(
    `/${path}/${
      salePageDetail[sales_page_detail_keys.url_slug] ||
      salePageDetail[sales_page_detail_keys.uuid]
    }`
  );
};

export const getSalePageUrl = ({ salePageDetail = {}, params = {} }) => {
  const url = appendUrlParams(
    `${getCreatorHostsiteURL().url}${getSalePagePathname({ salePageDetail })}`,
    {
      ...(params.preview ? { preview: true } : {}),
      ...(params.templateSlug ? { template: params.templateSlug } : {}),

      // @dev : This is done if we need to disable the animations for the
      // CT Sale Page Builder Preview Iframe
      ...(params.disableAnimation ? { disable_animation: true } : {}),
    }
  );

  return url;
};
