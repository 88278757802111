import React from "react";
import { StatusField as StatusFieldComponent } from "ui/pages/transactions/AllTransaction/components/TableFields/TableFields";
import { IPaymentLinksTransactions } from "../../interfaces/IPaymentLinksTransactions";
import { TRANSACTIONS_COLUMN_KEYS } from "features/Transactions/modules/GSTDetailsField/constants/Transactions.constants";

const StatusField = (record: IPaymentLinksTransactions.ITransaction) => {
  return (
    <StatusFieldComponent
      record={record}
      source={TRANSACTIONS_COLUMN_KEYS.payout_status}
    />
  );
};

export default StatusField;
