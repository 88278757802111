import { isPast } from "date-fns";
import React, { FC } from "react";
import ExpiredStatus from "./components/ExpiredStatus/ExpiredStatus";
import { IStatusField } from "./interfaces/IStatusField";

const StatusField: FC<IStatusField> = ({ record }) => {
  let isExpired = !!record.expiry_date && isPast(new Date(record.expiry_date));

  return isExpired ? <ExpiredStatus /> : null;
};

export default StatusField;
