import SessionStorageConstants from "constants/SessionStorage.constants";
import React from "react";
import { getSessionStorageItem } from "utils/getterAndSetters";

// @dev preloader.css and preloader.js serve the styles and preloader-text content here.
const ExlyPreloader = () => {
  return (
    <div id="preloader">
      <div className="loader-animation">
        <h1>EXLY</h1>
        <h1>EXLY</h1>
        <h1>EXLY</h1>
      </div>
      <p className="preloader-text">
        {getSessionStorageItem(SessionStorageConstants.LAST_PRELOADER_TEXT)}
      </p>
    </div>
  );
};

export default ExlyPreloader;
