import React from "react";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import classnames from "classnames";
import commonStyles from "ui/pages/SalesPages/components/common.module.css";
import styles from "./MenuPopoverComponent.module.css";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ExlyImage from "common/Components/ExlyImage";
import { ReactComponent as GoToTrafficAnalysisIcon } from "ui/pages/SalesPages/assets/GoToTrafficAnalysisIcon.svg";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import LinkIcon from "@material-ui/icons/Link";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import DeleteIcon from "@material-ui/icons/Delete";

const commonMenuPopoverItemClassName = classnames(
  styles.menuItem,
  "pointer d-flex align-items-center"
);
const commonMenuPopoverItemIconClassName = "mr-2";

export const MenuPopoverComponent = ({
  closePopover,
  onEditSalesPageClick,
  onEditSalePageTitleClick: onEditSalePageTitleClickProp,
  isSalesPageLive,
  isPgBuilderSourceGJS,
  onGoToTrafficAnalysisClick: onGoToTrafficAnalysisClickProp,
  onChangeOfferingClick,
  onDuplicatePageClick: onDuplicatePageClickProp,
  onCopySalePageLinkButtonClick: onCopySalePageLinkButtonClickProp,
  onShareButtonClick: onShareButtonClickProp,
  onPreviewSalePageBtnClick: onPreviewSalePageBtnClickProp,
  onDeletePageClick: onDeletePageClickProp,
}) => {
  const isDesktop = useDesktopMediaQuery();
  const onEditSalePageTitleClick = (e) => {
    if (e?.stopPropagation) e.stopPropagation();
    onEditSalePageTitleClickProp();
  };
  const onGoToTrafficAnalysisClick = (e) => {
    if (e?.stopPropagation) e.stopPropagation();
    onGoToTrafficAnalysisClickProp();
  };
  const onDuplicatePageClick = (e) => {
    if (e?.stopPropagation) e.stopPropagation();
    onDuplicatePageClickProp();
  };
  const onCopySalePageLinkButtonClick = (e) => {
    if (e?.stopPropagation) e.stopPropagation();
    onCopySalePageLinkButtonClickProp();
  };
  const onShareButtonClick = (e) => {
    if (e?.stopPropagation) e.stopPropagation();
    onShareButtonClickProp();
  };
  const onPreviewSalePageBtnClick = (e) => {
    if (e?.stopPropagation) e.stopPropagation();
    onPreviewSalePageBtnClickProp();
  };
  const onDeletePageClick = (e) => {
    if (e?.stopPropagation) e.stopPropagation();
    onDeletePageClickProp();
  };

  return (
    <>
      {!isDesktop ? (
        <div
          className={classnames(
            commonStyles.link,
            commonMenuPopoverItemClassName
          )}
          onClick={(e) => {
            if (e?.stopPropagation) e.stopPropagation();
            onEditSalesPageClick();
            closePopover();
          }}
        >
          <EditOutlinedIcon className={commonMenuPopoverItemIconClassName} />
          Edit page
        </div>
      ) : null}
      <div
        className={classnames(
          commonStyles.link,
          commonMenuPopoverItemClassName
        )}
        onClick={(e) => {
          if (e?.stopPropagation) e.stopPropagation();
          onEditSalePageTitleClick();
          closePopover();
        }}
      >
        <ExlyImage
          className={commonMenuPopoverItemIconClassName}
          src="assets/vectors/sales_pages/edit_title.svg"
        />
        Edit title
      </div>
      {!isPgBuilderSourceGJS ? (
        <div
          className={classnames(commonMenuPopoverItemClassName)}
          onClick={(e) => {
            if (e?.stopPropagation) e.stopPropagation();
            onChangeOfferingClick();
            closePopover();
          }}
        >
          <ExlyImage
            className={commonMenuPopoverItemIconClassName}
            src="assets/vectors/sales_pages/change_offering.svg"
          />
          Change Offering
        </div>
      ) : null}
      {/**
         * // TODO @mayank: show only for custom sales pages

        {isSalesPageLive ? (
          <div
            className={classnames(
              commonStyles.link,
              commonMenuPopoverItemClassName
            )}
            onClick={(e) => {
              // TODO @mayank: handle for custom sales pages
              if (e?.stopPropagation) e.stopPropagation();

              closePopover();
            }}
          >
            <ExlyImage
              className={commonMenuPopoverItemIconClassName}
              src="assets/vectors/sales_pages/edit_tab_assets.svg"
            />
            Edit tab assets
          </div>
        ) : null} 
        */}
      {isSalesPageLive ? (
        <div
          className={classnames(
            commonMenuPopoverItemClassName,
            "d-flex justify-content-between"
          )}
          onClick={(e) => {
            if (e?.stopPropagation) e.stopPropagation();
            onGoToTrafficAnalysisClick();
            closePopover();
          }}
        >
          <div>
            <GoToTrafficAnalysisIcon
              className={commonMenuPopoverItemIconClassName}
              width="20"
              height="20"
            />
            Go to Traffic Analysis
          </div>
          <ArrowForwardIcon
            className={classnames(
              styles.goToTrafficAnalysisTopRightIcon,
              "ml-5"
            )}
          />
        </div>
      ) : null}
      <div
        className={commonMenuPopoverItemClassName}
        onClick={(e) => {
          if (e?.stopPropagation) e.stopPropagation();
          onDuplicatePageClick();
          closePopover();
        }}
      >
        <FileCopyOutlinedIcon
          className={classnames(
            styles.menuPopoverItemMUIIcon,
            commonMenuPopoverItemIconClassName
          )}
        />
        Duplicate page
      </div>
      {isSalesPageLive && !isDesktop ? (
        <div
          className={commonMenuPopoverItemClassName}
          onClick={(e) => {
            if (e?.stopPropagation) e.stopPropagation();
            onCopySalePageLinkButtonClick();
            closePopover();
          }}
        >
          <LinkIcon
            className={classnames(
              styles.menuPopoverItemMUIIcon,
              styles.copyButtonIcon,
              commonMenuPopoverItemIconClassName
            )}
          />
          Copy link
        </div>
      ) : null}
      {isSalesPageLive ? (
        <div
          className={commonMenuPopoverItemClassName}
          onClick={(e) => {
            if (e?.stopPropagation) e.stopPropagation();
            onShareButtonClick();
            closePopover();
          }}
        >
          <ShareOutlinedIcon
            className={classnames(
              styles.menuPopoverItemMUIIcon,
              commonMenuPopoverItemIconClassName
            )}
          />
          Share
        </div>
      ) : null}
      {!isDesktop ? (
        <div
          className={commonMenuPopoverItemClassName}
          onClick={(e) => {
            if (e?.stopPropagation) e.stopPropagation();
            onPreviewSalePageBtnClick();
            closePopover();
          }}
        >
          <VisibilityOutlinedIcon
            className={classnames(
              styles.menuPopoverItemMUIIcon,
              commonMenuPopoverItemIconClassName
            )}
          />
          Preview page
        </div>
      ) : null}
      <div
        className={classnames(commonMenuPopoverItemClassName, "text-danger")}
        onClick={(e) => {
          if (e?.stopPropagation) e.stopPropagation();
          onDeletePageClick();
          closePopover();
        }}
      >
        <DeleteIcon
          className={classnames(
            styles.menuPopoverItemMUIIcon,
            commonMenuPopoverItemIconClassName
          )}
        />
        Delete page
      </div>
    </>
  );
};
