export const WHATSAPP_TEMPLATE_ENGLISH_LANGUAGE_OPTION = {
  label: "English",
  value: "en_US",
};

export const WHATSAPP_TEMPLATE_LANGUAGE_DEFAULT =
  WHATSAPP_TEMPLATE_ENGLISH_LANGUAGE_OPTION;

export const WHATSAPP_TEMPLATE_LANGUAGES_OPTIONS = [
  WHATSAPP_TEMPLATE_ENGLISH_LANGUAGE_OPTION,
  { label: "Hindi", value: "hi" },
  { label: "Bengali", value: "bn" },
  { label: "Gujarati", value: "gu" },
  { label: "Kannada", value: "kn" },
  { label: "Malayalam", value: "ml" },
  { label: "Marathi", value: "mr" },
  { label: "Punjabi", value: "pa" },
  { label: "Tamil", value: "ta" },
  { label: "Telugu", value: "te" },
  { label: "Urdu", value: "ur" },
];
