import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React from "react";

export const MenuButtonComponent = ({ openPopover }) => {
  return (
    <span
      className="pointer py-2"
      onClick={(e) => {
        if (e?.stopPropagation) e.stopPropagation();
        openPopover(e);
      }}
    >
      <MoreHorizIcon />
    </span>
  );
};
