import Button from "@material-ui/core/Button";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import classnames from "classnames";
import React from "react";
import styles from "./VisibleTickCheckbox.module.css";

const VisibleTickCheckbox = ({ active, onClick, disabled }) => {
  return (
    <Button
      className={classnames(
        styles.root,
        active ? styles.active : styles.inactive,
        disabled && styles.disabled
      )}
      disabled={disabled}
      onClick={onClick}
    >
      <CheckRoundedIcon />
    </Button>
  );
};
export default VisibleTickCheckbox;
