import ExlyTable from "common/Components/ExlyTable";
import useOfferingAutoDebitEnabled from "features/Listings/utils/useOfferingAutoDebitEnabled";
import React from "react";
import { ReactAdminComponentProps } from "react-admin";
import { REPORT_TYPES } from "ui/pages/Reports/constants";
import { FilteredEmptyState } from "ui/pages/transactions/AllTransaction/components/TableFields/TableFields";
import { emptyStateProps } from "ui/pages/transactions/AllTransaction/transactions.constants";
import { useNotifications } from "utils/hooks";
import useOrgMemberList from "utils/hooks/useOrgMemberList";
import { orgPermissions, RESOURCE_KEYS } from "utils/OrgPermissions";
import { exportLargeListingData } from "utils/Utils";
import DrawerFooter from "./components/DrawerFooter/DrawerFooter";
import StatusField from "./components/StatusField/StatusField";
import WhatsappNode from "./components/WhatsappNode/WhatsappNode";
import {
  PAYMENT_LINKS_TRANSACTIONS_TABLE_CONFIG,
  PAYMENT_LINKS_TRANSACTIONS_TABLE_FILTERS_CONFIG,
  tableChipsFormatter,
} from "./constants/PaymentLinksTransactions.constants";
import {
  getRecordFooterClassName,
  handleSeeMore,
} from "./utils/PaymentLinksTransactions.utils";

const PaymentLinksTransactions = (props: ReactAdminComponentProps) => {
  const { isOfferingAutoDebitEnabled } = useOfferingAutoDebitEnabled();
  const { orgMembers } = useOrgMemberList();
  const { notify } = useNotifications();

  const showStaffFilter =
    orgPermissions.isOwner() ||
    orgPermissions.isMainUser() ||
    orgPermissions.isManager();

  const isCustomerDetailsHidden = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SECTIONS.ALL_CUSTOMERS
  );

  const hidePricePerSlot = orgPermissions.isFieldHidden(
    RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS,
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS,
    RESOURCE_KEYS.FIELDS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS.PRICE_PER_SLOT
  );

  const hideTransactionAmount = orgPermissions.isFieldHidden(
    RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS,
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS,
    RESOURCE_KEYS.FIELDS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS.TRANSACTION_AMOUNT
  );

  const hideNumberOfUnits = orgPermissions.isFieldHidden(
    RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS,
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS,
    RESOURCE_KEYS.FIELDS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS.NO_OF_UNITS
  );

  const hidePaymountAmount = orgPermissions.isFieldHidden(
    RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS,
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS,
    RESOURCE_KEYS.FIELDS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS.PAYOUT_AMOUNT
  );

  const hidePayoutStatus = orgPermissions.isFieldHidden(
    RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS,
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS,
    RESOURCE_KEYS.FIELDS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS.PAYOUT_STATUS
  );

  const hidePayoutDate = orgPermissions.isFieldHidden(
    RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS,
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS,
    RESOURCE_KEYS.FIELDS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS.PAYOUT_DATE
  );

  const handleExport = ({
    filterValues,
    isEmpty,
  }: {
    filterValues: { [filterKey: string]: string | number | boolean };
    isEmpty: boolean;
  }) =>
    exportLargeListingData({
      filterValues: { ...filterValues, payment_link_transactions: true },
      reportType: REPORT_TYPES.TRANSACTIONS,
      notify,
      isEmpty,
    });

  return (
    <ExlyTable
      ra_props={props}
      tableTitle="Transactions"
      columnConfig={PAYMENT_LINKS_TRANSACTIONS_TABLE_CONFIG({
        hidePricePerSlot,
        hideTransactionAmount,
        hideNumberOfUnits,
        hidePaymountAmount,
        hidePayoutStatus,
        hidePayoutDate,
        isCustomerDetailsHidden,
        isOfferingAutoDebitEnabled,
      })}
      layoutProps={{
        showBackCta: true,
        paddingDesktop: "0",
        paddingBottom: "120px",
        layoutDesktopMargin: "20px 24px",
      }}
      tableFilters={PAYMENT_LINKS_TRANSACTIONS_TABLE_FILTERS_CONFIG({
        staffMembers: orgMembers,
        showStaffMembers: showStaffFilter,
        isOfferingAutoDebitEnabled,
      })}
      tableChipsFormatter={tableChipsFormatter(orgMembers)}
      filteredEmptyState={<FilteredEmptyState />}
      noExportButton={!orgPermissions.canExport()}
      customHandleExport={handleExport}
      renderPrimaryColumnRightNode={WhatsappNode}
      getRecordFooterClassName={getRecordFooterClassName}
      customPrimaryAction={{
        label: "See More",
        onClick: handleSeeMore,
      }}
      renderSecondaryAction={StatusField}
      renderDrawerFooter={DrawerFooter}
      fieldsLeftPadded
      borderedFields
      drawerFieldsBordered
      fieldsAlignment="space-between"
      drawerFieldsAlignment="space-between"
      recordFooterVariant="secondary"
      primaryKey="id"
      emptyStateProps={emptyStateProps}
    />
  );
};

export default PaymentLinksTransactions;
