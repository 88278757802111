import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { EXLY_GLOBAL__SET_NAV_BAR_SECONDARY_ACTION } from "redux/actions";
import HelpIcon from "@material-ui/icons/HelpOutlineOutlined";

const useLearnMoreCtaOnMobileAppbar = ({ href }: { href: string }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!href) return;

    const handleOpenLink = () => {
      window.open(href, "_blank");
    };

    dispatch({
      type: EXLY_GLOBAL__SET_NAV_BAR_SECONDARY_ACTION,
      payload: {
        show: true,
        label: "Learn more",
        btnProps: {
          color: "tertiary",
          variant: "text",
          size: "thin",
          disableTouchRipple: true,
          startIcon: <HelpIcon />,
          onClick: handleOpenLink,
          className: "text-nowrap",
        },
      },
    });

    return () => {
      dispatch({
        type: EXLY_GLOBAL__SET_NAV_BAR_SECONDARY_ACTION,
        payload: {
          show: false,
          label: "",
          btnProps: {},
        },
      });
    };
  }, [href]);
};

export default useLearnMoreCtaOnMobileAppbar;
