import React from "react";
import useInfiniteSearch from "hooks/useInfiniteSearch";
import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { FORMSV2_API } from "./FormsV2.utils";
import { DEFAULT_FORMS_PAGE_SIZE } from "features/CourseAnalytics/modules/ContentResponses/modules/FormsV2/modules/DesktopForms/utils/DesktopView.utils";

/**
 * hook uses InfiniteSearch to load more the forms list in infinite scroll manner.
 */
export const useInfiniteFormOptions = ({ bookingUuid, listingUuid } = {}) => {
  const {
    data: options,
    loading,
    setLoading,
    setError,
    setHasMore,
    pageNumber,
    setData,
    lastElementRef,
  } = useInfiniteSearch();
  const queryParams = {
    booking_uuid: bookingUuid,
    listing_uuid: listingUuid,
    page_size: DEFAULT_FORMS_PAGE_SIZE,
    page: pageNumber,
  };

  React.useEffect(() => {
    setLoading(true);
    setError(false);

    dataProvider
      .custom_request(
        FORMSV2_API.GET_PAGINATED_FORMS_LIST,
        apiMethods.GET,
        queryParams
      )
      .then((response) => {
        const { forms, total_pages } = response?.data || {};
        setHasMore(total_pages > pageNumber);
        setData((prevData) => [...prevData, ...forms]);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [pageNumber]);

  return {
    loading,
    options,
    lastElementRef,
  };
};
