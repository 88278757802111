export const BUTTON_TYPES = {
  NONE: { value: 0, label: "None" },
  CALL_TO_ACTION: { value: 1, label: "Call-to-action" },
};

export const TEMPLATE_FORM_KEYS = {
  locale: "locale",
  cta_radio: "cta_radio",
  cta_data: "cta_data",
};

export const WHATSAPP_TEMPLATES_ROUTE_KEYS = {
  template_list: "template_list",
};

export const WHATSAPP_TEMPLATES_ROUTE_IDS = {
  [WHATSAPP_TEMPLATES_ROUTE_KEYS.template_list]: "whatsapp-templates/:id",
};

export const WHATSAPP_TEMPLATES_PATHNAMES = {
  [WHATSAPP_TEMPLATES_ROUTE_KEYS.template_list]: (id) =>
    `whatsapp-templates/${id}`,
};
