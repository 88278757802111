import { IconButton } from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import React, { FC } from "react";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { validURLV2 } from "utils/validations";
import styles from "./LinkField.module.css";
import { ILinkField } from "./interfaces/ILinkField";

const LinkField: FC<ILinkField> = ({ link }) => {
  const { notify } = useNotifications();

  if (!link) return <>N/A</>;

  const showLink = validURLV2(link);

  const handleCopyLink = () => {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(link);
      notify("Link Copied!", notification_color_keys.info);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.linkWrapper}>
        {showLink ? (
          <a href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        ) : (
          <span>{link}</span>
        )}
      </div>

      {showLink ? (
        <IconButton onClick={handleCopyLink}>
          <FileCopyOutlinedIcon />
        </IconButton>
      ) : null}
    </div>
  );
};

export default LinkField;
