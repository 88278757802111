import { PAYMENT_LINKS_TRANSACTIONS_APIS } from "./PaymentLinksTransactions.api";
import {
  PAYMENT_LINK_TRANSACTIONS_ROUTE_IDS,
  PAYMENT_LINKS_TRANSACTIONS_ROUTE_KEYS,
} from "./PaymentLinksTransactions.constants";

export const payment_links_resource_apis = {
  [PAYMENT_LINK_TRANSACTIONS_ROUTE_IDS[
    PAYMENT_LINKS_TRANSACTIONS_ROUTE_KEYS.PAYMENT_LINKS_TRANSACTIONS
  ]]: {
    api_end_point:
      PAYMENT_LINKS_TRANSACTIONS_APIS.get_payment_links_transactions,
    queries: {
      payment_link_transactions: true,
    },
  },
};
